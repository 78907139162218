var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn-toggle',{attrs:{"borderless":"","mandatory":""},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","value":"gantt","color":"primary"},on:{"click":function($event){return _vm.$emit('change_view', 'gantt')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","color":"primary lighten-1"}},[_vm._v(" reorder ")])],1)]}}])},[_c('span',[_vm._v("Vista Gantt")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","value":"kanban","color":"primary"},on:{"click":function($event){return _vm.$emit('change_view', 'kanban')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","color":"primary lighten-1"}},[_vm._v(" view_week ")])],1)]}}])},[_c('span',[_vm._v("Vista Kanban")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"list","text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('change_view', 'list')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","color":"primary lighten-1"}},[_vm._v(" view_list ")])],1)]}}])},[_c('span',[_vm._v("Vista Lista")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }